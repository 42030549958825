import React from "react";
import { Link } from "gatsby";
import Header from "../components/header";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header notFullHeight />
    <div className="jumbotron not-found">
      <h1 className="not-found__heading">Uh ohh! 404!</h1>
      <p className="not-found__heading--sub">We can’t find the page that you’re looking for.</p>
      <Link to="/" className="not-found__button">Back to homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
